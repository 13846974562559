import './App.css';
import { Routes, Route,useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/Signin';
import Taxonomy from './pages/Taxonomy';
import Page404 from './pages/Page404';
import Users from './pages/Users';


function App() {
  
  const config = { 
    appName: 'WILO',
    appDesc: 'Smart Captive Portal Wifi',
    appDomain: 'NetMaster.ID',
    appTitle : "NetMaster.ID - Network Services Management",
    locStorage : 'wilo_cache',
    //home : 'http://localhost:3000/',
    home : 'https://wilo.netmaster.id/',
    hostApi : 'https://api-portal.netmaster.id/',
    ver : 'ver. 0.24.10'
  }
  const [cookies] = useCookies(['bas_']);

  return (
    <Routes>
      <Route path="*" element={<Page404 />} />
      <Route path="/" element={<Dashboard config={config} cookies={cookies} />} />
      
      <Route path="/signin" element={<SignIn config={config} />} />
      <Route path="/signout" element={<SignIn config={config} action="signout" />} />

      <Route path="/dashboard" element={<Dashboard config={config} />} />

      <Route path="/users" element={<Users config={config} />} />
      <Route path="/taxo" element={<Taxonomy config={config} />} /> 

    </Routes>
  );
}

export default App;
