import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiBase  from '@mui/base';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import MsgBox from '../lib/MsgBox';
import profileIcon from '../Assets/Images/profile-icon.png'
import { useForm, SubmitHandler } from "react-hook-form"


const DrawerWaQr = forwardRef((props,ref) => {
    const [cookie,setCookie,removeCookie] = useCookies(['bas_']);
    const formRef = useRef();
    const chpformRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [loadingchp,setLoadingchp] = React.useState(false)
    const [taxo,setTaxo] = React.useState([])
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, resetField, handleSubmit,reset } = useForm()
  
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookie.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'profile/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              setCookie('token', result.data.token,{secure: true, sameSite: 'none'});
              setCookie('name', result.data.user.name,{secure: true, sameSite: 'none'});
              setCookie('phone', result.data.user.phone,{secure: true, sameSite: 'none'});
              setCookie('profile',true)
              setValue('name',result.data.user.name)
              setValue('phone',result.data.user.phone)
              //Reset Form
              reset()
              setError([])
              window.location.reload(); 
              loadingOff()
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Update Profile',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            loadingOff()
          })
          
    }

    const chPass = (data) => {
      setLoadingchp(true)
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookie.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(chpformRef.current));

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'profile/change-pass', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              //Reset Form
              setError([])
              setLoadingchp(false)

              resetField('current_password')
              resetField('new_password')
              resetField('new_password_confirmation')
              setisMsgBox({
                open: true,
                status: 'success',
                title:'Change Password',
                msg: 'Change password successfully',
              })
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              setLoadingchp(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Change Password',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            setLoadingchp(false)
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false
    });

    useImperativeHandle(ref, () => ({
      openForm: (config) => {
        setState({ isForm: true})
        getTaxo(config)
        setValue('username',cookie.username)
        setValue('name',cookie.name)
        setValue('phone',cookie.phone)
      }
   }),[cookie]);

  const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
  const getTaxo = (config) => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookie.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("object", ['level']);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(config.hostApi + 'taxo', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              loadingOff()
              setTaxo(result.data.level)
              setError([])
              
            }else{
              if(result.errors){
                setError(result.errors)
              }else{
                setError([])
              }
              loadingOff()
            }
          })
          .then((response)=>{
            loadingOff()
          })

  }
  React.useEffect(() => {
  },[cookie]);

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>

      
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                WA QR Code
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({isForm:false})}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
            <iframe src={"https://jogja.wablas.com/api/device/scan?token=" + cookie.token_wa}
              style={{border: '0px solid', width:'100%', height:'100vh'}}
            ></iframe>
        </Mui.DialogContent>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerWaQr;
